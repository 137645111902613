<template>
  <div
    ref="component"
    class="InlineField__Component"
    @mouseover="hover(true)"
    @mouseleave="hover(false)"
    :class="{
      'has-value': nullIsValue || value !== null,
      'read-only': readOnly,
      'hide-hover': hideHover,
      invalid: required && !value,
    }"
  >
    <label>
      <div v-if="label" class="InlineField__Component__Placeholder">
        <span class="InlineField__Component__Placeholder__Text">{{
          label
        }}</span>
        <span class="InlineField__Component__Placeholder__Icon">
          <b-icon v-if="!hideHover" icon="pencil" size="is-small"> </b-icon
        ></span>
      </div>
      <div class="alert">
        <b-icon v-if="alertNotExist && notExist" icon="alert-circle"></b-icon>
      </div>
      <v-select
        v-if="data"
        :tabindex="tabIndex"
        dir="rtl"
        label="Text"
        :placeholder="placeholderText"
        :options="data"
        :clearable="clearable"
        :filterable="filterable"
        :selectOnTab="true"
        :disabled="readOnly"
        @input="setSelected"
        @search="
          (search, loading) => {
            this.fetchOptions &&
              this.fetchOptions({
                search,
                loading,
                setData: this.setData,
                setValue: this.setValue,
              });
          }
        "
        :reduce="reduceFunction"
        :value="value"
      >
        {{ value }}
        <div slot="no-options">לא נמצאו ערכים דומים</div>
      </v-select>
    </label>
  </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/src/scss/vue-select.scss";

export default {
  name: "FieldInlineSelect",
  props: [
    "label",
    "required",
    "value",
    "options",
    "optionsAsync",
    "clearable",
    "filterable",
    "readOnly",
    "hideHover",
    "nullIsValue",
    "placeholder",
    "fetchOptions",
    "reduce",
    "alertNotExist",
    "immediateChoose",
    "tabIndex",
  ],
  components: {
    vSelect,
  },
  computed: {
    placeholderText() {
      return this.placeholder || "בחר ערך מהרשימה";
    },
    reduceFunction() {
      if (this.reduce) {
        return this.reduce;
      }
      return (item) => item.Value;
    },
    notExist() {
      return this.value?.Value === -1;
    },
  },
  data() {
    return {
      isFocused: false,
      data: [],
    };
  },
  mounted() {
    const val = this.immediateChoose ? this.value : "";
    if (this.optionsAsync) {
      this.optionsAsync()("").then((r) => {
        this.data = r;
        if (val) {
          this.setValue(val);
        }
      });
    } else if (this.options) {
      this.data = this.options.slice(0);
    }
  },
  methods: {
    setData(data) {
      this.data = data.slice(0);
    },
    setValue(value) {
      this.setSelected(value);
    },
    setSelected(value) {
      this.$emit("input", value);
      this.$emit("onChange", value);
    },
    hover(isHover) {
      if (isHover) {
        this.$refs.component.classList.add("is-hover");
      } else {
        this.$refs.component.classList.remove("is-hover");
      }
    },
    focus(isFocused) {
      if (isFocused) {
        this.$refs.component.classList.add("is-focused");
      } else {
        this.$refs.component.classList.remove("is-focused");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .vs__dropdown-toggle {
  border: none;
}
body :not(.hide-hover) ::v-deep .vs__actions {
  opacity: 0;
}
.InlineField__Component {
  padding: 5px 0;
  position: relative;

  &:not(.read-only) {
    &.is-hover .InlineField__Component__Placeholder,
    &.has-value .InlineField__Component__Placeholder,
    &.is-focused .InlineField__Component__Placeholder {
      transform: translateY(0px);
    }
    &.is-hover input,
    &.is-hover:not(.hide-hover) ::v-deep .v-select,
    &.is-focused input {
      border-bottom: 1px solid #00a4bd;
    }

    &.is-hover ::v-deep .vs__actions {
      opacity: 1;
    }
  }

  &.has-value .InlineField__Component__Placeholder {
    transform: translateY(0px);
  }

  .InlineField__Component__Placeholder__Icon {
    color: #00a4bd;
    opacity: 0;
    cursor: pointer;
  }
  &.read-only {
    .InlineField__Component__Placeholder__Icon {
      display: none;
    }
  }

  &.is-hover:not(.is-focused):not(.has-value)
    .InlineField__Component__Placeholder__Icon {
    opacity: 1;
  }

  .InlineField__Component__Placeholder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transform: translateY(10px);
    transition: transform linear;

    .InlineField__Component__Placeholder__Text {
      color: #287994;
      font-size: 14px;
      font-weight: 500;
    }
  }

  &.invalid label ::v-deep .v-select {
    background-color: #e9f5ff;
  }

  label {
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }
  &.read-only {
    label {
      cursor: default;
    }
  }
  input,
  ::v-deep .v-select {
    border: none;
    border-bottom: 1px solid transparent;
    min-width: 100px;
    border-radius: 0;
    transition: all linear 0.1s;
    font-size: 16px;
    cursor: pointer;

    &:focus {
      outline: none;
      cursor: text;
    }
  }
  ::v-deep .vs__search {
    cursor: pointer;

    &:focus {
      cursor: text;
    }
  }

  ::v-deep .vs__dropdown-menu {
    width: 300px;
  }
  .alert {
    position: absolute;
    right: -25px;
    top: 25px;
  }
}
</style>
