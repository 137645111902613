var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "component",
      staticClass: "InlineField__Component",
      class: {
        "has-value": _vm.nullIsValue || _vm.value !== null,
        "read-only": _vm.readOnly,
        "hide-hover": _vm.hideHover,
        invalid: _vm.required && !_vm.value
      },
      on: {
        mouseover: function($event) {
          return _vm.hover(true)
        },
        mouseleave: function($event) {
          return _vm.hover(false)
        }
      }
    },
    [
      _c(
        "label",
        [
          _vm.label
            ? _c(
                "div",
                { staticClass: "InlineField__Component__Placeholder" },
                [
                  _c(
                    "span",
                    {
                      staticClass: "InlineField__Component__Placeholder__Text"
                    },
                    [_vm._v(_vm._s(_vm.label))]
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "InlineField__Component__Placeholder__Icon"
                    },
                    [
                      !_vm.hideHover
                        ? _c("b-icon", {
                            attrs: { icon: "pencil", size: "is-small" }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ]
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "alert" },
            [
              _vm.alertNotExist && _vm.notExist
                ? _c("b-icon", { attrs: { icon: "alert-circle" } })
                : _vm._e()
            ],
            1
          ),
          _vm.data
            ? _c(
                "v-select",
                {
                  attrs: {
                    tabindex: _vm.tabIndex,
                    dir: "rtl",
                    label: "Text",
                    placeholder: _vm.placeholderText,
                    options: _vm.data,
                    clearable: _vm.clearable,
                    filterable: _vm.filterable,
                    selectOnTab: true,
                    disabled: _vm.readOnly,
                    reduce: _vm.reduceFunction,
                    value: _vm.value
                  },
                  on: {
                    input: _vm.setSelected,
                    search: function(search, loading) {
                      this$1.fetchOptions &&
                        this$1.fetchOptions({
                          search: search,
                          loading: loading,
                          setData: this$1.setData,
                          setValue: this$1.setValue
                        })
                    }
                  }
                },
                [
                  _vm._v(" " + _vm._s(_vm.value) + " "),
                  _c(
                    "div",
                    { attrs: { slot: "no-options" }, slot: "no-options" },
                    [_vm._v("לא נמצאו ערכים דומים")]
                  )
                ]
              )
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }